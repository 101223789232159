import { render, staticRenderFns } from "./CardHeader.vue?vue&type=template&id=8a22fd3c&scoped=true"
import script from "./CardHeader.vue?vue&type=script&lang=js"
export * from "./CardHeader.vue?vue&type=script&lang=js"
import style0 from "./CardHeader.vue?vue&type=style&index=0&id=8a22fd3c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a22fd3c",
  null
  
)

export default component.exports