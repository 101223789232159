<template>
    <div>
      <WallpapersView/>
    </div>
  </template>
  
  <script>
  import WallpapersView from "../components/wallpapers/WallpapersView.vue";
  
  export default {
    name: "Wallpapers",
    components: {
      WallpapersView,
    },
  };
  </script>