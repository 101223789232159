<template>
  <div>
    <div v-if="device.name">
      <h5 class="center upper-bold">
        {{ device.manufacturer }} {{ device.name }} ({{ device.codename }})
      </h5>
      <div class="row limiter">
        <div class="col s12 m12">
          <div class="builds cardColor">
            <div class="deviceprop">
              <i class="material-icons">domain</i>
              <h6>{{ device.manufacturer }}</h6>
            </div>

            <div class="deviceprop">
              <i class="material-icons">phone_android</i>
              <h6>{{ device.name }}</h6>
            </div>

            <div class="deviceprop">
              <i class="material-icons">device_unknown</i>
              <h6>{{ device.codename }}</h6>
            </div>

            <div>
              <div v-if="device.active">
                <div v-for="maintainer in device.maintainers" :key="maintainer.name">
                  <div class="deviceprop">
                    <i class="material-icons">person_outline</i>
                    <h6 class="link" v-on:click="openLink(maintainer.link)">
                      {{ maintainer.name }}
                    </h6>
                    <Flag width="20px" :country="maintainer.country" />
                  </div>
                </div>
              </div>
              <div v-else>
                <i class="material-icons red-icon">person_outline</i>
                <h6>No maintainer</h6>
              </div>
            </div>

            <div class="buildButtons" style="text-align: center">
              <a v-if="device.xda_thread && device.active" v-bind:href="device.xda_thread" target="_blank"
                class="btn buttonInsideCard">XDA Thread</a>
              <a v-if="instructions && device.active" v-on:click="openInstructionsModal"
                class="btn buttonInsideCard">Instructions</a>
            </div>
            <div v-if="device.donation_link && device.active" class="card-action xda-buttons">
              <a v-bind:href="device.donation_link" target="_blank" class="btn">Donate to Maintainer</a>
            </div>

            <div v-if="!device.active" class="card-action xda-buttons" style="text-align: center">
              <a href="https://forms.gle/LNKgCEuzje5z5hni9" target="_blank" class="waves-effect btn red-bg">Become the
                maintainer</a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!device.active" class="row limiter">
        <div class="col s12 m12">
          <div class="red-bg alert-box">
            <p>This device will no longer receive updates</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for instructions -->
    <div id="instructionsModal" class="modal builds cardColor">
      <div class="modal-content">
        <h4>Instructions</h4>
        <VueMarkdown loading="lazy" :source="instructions" class="md"></VueMarkdown>
      </div>
      <div class="modal-footer" style="background: none;">
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
      </div>
    </div>
  </div>
</template>

<script>

import Flag from "../common/Flag.vue";
import VueMarkdown from "@theori/vue-markdown";

export default {
  name: "CardHeader",
  components: {
    Flag,
    VueMarkdown
  },
  data() {
    return {
      instructions: '',
    };
  },
  computed: {
    device() {
      this.$store.dispatch("device/filterDevice", this.$route.params);
      const { device } = this.$store.state.device;

      if (device && device.name) {
        document.title = `${device.name} (${this.$route.params.codename}) | Paranoid Android Download Center`;
        this.$store.dispatch("device/fetchInstructionsMD", device.codename)
          .then(instructions => {
            this.instructions = instructions;
          })
      }

      return device;
    },
  },
  methods: {
    openLink(link) {
      window.open(link);
      this.showToast("Opening maintainer profile");
    },
    showToast(message) {
      if (this.$toast != null) {
        this.$toast.clear();
      }

      this.$toast(message, {
        position: "bottom-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        closeButton: "button",
        icon: false,
        rtl: false,
        toastClassName: "my-custom-toast-class",
      });
    },
    openInstructionsModal() {
      const elem = document.getElementById('instructionsModal');
      const instance = M.Modal.init(elem, {
        opacity: 0.7,
        inDuration: 250,
        outDuration: 250,
        onCloseEnd: function () {}
      });
      instance.open();
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .modals {
    max-height: fit-content;
  }

  .Vue-Toastification__toast--default {
    margin-left: 10% !important;
    margin-right: 10% !important;
    min-width: 200px !important;
  }

  .Vue-Toastification__container .Vue-Toastification__toast {
    width: auto !important;
  }
}

.builds::-webkit-scrollbar {
  width: 0;
}

.Vue-Toastification__toast--default {
  background-color: var(--card);
  color: var(--accent);
}

.Vue-Toastification__progress-bar {
  background-color: var(--accent);
}

.Vue-Toastification__close-button {
  color: var(--accent);
}
</style>
